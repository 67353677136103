import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';

// Connects to data-controller="notification"
export default class extends Controller {
  static targets = ['container'];

  connect() {
    enter(this.containerTarget);

    setTimeout(() => {
      this.close();
    }, 5000);
  }

  close() {
    leave(this.containerTarget);

    setTimeout(() => {
      this.element.remove();
    }, 3000);
  }
}
